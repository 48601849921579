import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import ButtonGroup from "../molecules/ButtonGroup"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "../reveals/TextReveal"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const CallToActionBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      <div className="flex flex-wrap mt-8 flew-row -mx-grid">
        <div className="w-full mb-4 lg:w-1/2 lg:mb-0 px-grid">
          <TextReveal>
            <Richtext text={block.text} />
          </TextReveal>
          {block.image?.filename?.length > 0 && (
            <ButtonGroup className="mt-4" buttons={block.buttons} />
          )}
        </div>
        <div className="w-full lg:w-1/2 px-grid">
          {block.image?.filename?.length > 0 ? (
            <Image image={block.image} aspectRatio="16by9" />
          ) : (
            <ButtonGroup className="lg:mt-1" buttons={block.buttons} />
          )}
        </div>
      </div>
    </BlockWrapper>
  )
}

export default CallToActionBlock
